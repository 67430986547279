.article {

    &__title {
        color: @color-sherpa-blue;
        font-size: 24px;
        font-weight: 600;
    }

    &__date {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 400;
        color: @color-dusty-grey;
        margin-bottom: 18px;

        &:before {
            content: '';
            display: block;
            height: 1px;
            width: 17px;
            background: currentColor;
            margin-bottom: 5px;
        }
    }

    &__image {
        margin-bottom: 20px;
        width: 100%;

        img {
            width: 100%;
        }
    }

    &__body {
        color: @color-tundora;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 23px;
    }
}