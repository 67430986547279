.timeline {

    max-width: 1190px;
    margin: 0 auto 50px auto;
    font-size: 0;
    position: relative;

    @media @tablet {
        text-align: justify;
    }

    &:after {
        @media @tablet {
            display: inline-block;
            width: 100%;
            content: "";
        }
    }

    &:before {
        content: '';
        position: absolute;
        width: 2px;
        background: @color-white;
        top: 0;
        left: 21px;
        bottom: 30px;

        @media @tablet {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin-left: 40px;

        @media @tablet {
            margin: 0;
            display: inline-block;
            width: 47%;
            vertical-align: top;
        }

        &.timeline__left {

            @media @tablet {
                margin-top: 170px;
            }
        }
    }

    &__left {
        display: none;

        @media @tablet {
            display: inline-block;
        }
    }

    &__right {
        display: none;

        @media @tablet {
            display: inline-block;
        }
    }

    &__mobile {
        @media @tablet {
            display: none;
        }
    }

    &__item {
        background: @color-white;
        margin-bottom: 29px;
        border-top: 4px solid @color-sherpa-blue;

        padding: 20px 26px 20px 26px;
        position: relative;

        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 14px;
            height: 14px;
            background: @color-sherpa-blue;
            border-radius: 50%;
            z-index: 1;
            left: -25px;
            top: -8px;

            @media @tablet {
                left: -7.85%;

                .timeline__left & {
                    left: auto;
                    right: -7.85%;
                }
            }

            @media @desktop {
                left: -7.6%;

                .timeline__left & {
                    left: auto;
                    right: -7.6%;
                }
            }
        }

        @media @desktop {
            padding: 30px 56px 30px 56px;
        }

        &--expandable {
            .timeline__less {
                display: none;
            }
            .timeline__more {
                display: inline;
            }
        }

        &--expanded {
            .timeline__less {
                display: inline;
            }
            .timeline__more {
                display: none;
            }
        }
    }

    &__title {
        font-size: 24px;
        color: @color-sherpa-blue;
        font-weight: 600;
        display: block;
        margin-bottom: 9px;
    }

    &__summary {
        color: @color-tundora;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 23px;
    }

    &__body {
        color: @color-tundora;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 23px;

        height: 0;
        overflow: hidden;
        .transition-bezier();
    }

    &__date {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 400;
        color: @color-dusty-grey;
        margin-bottom: 27px;

        &:before {
            content: '';
            display: block;
            height: 1px;
            width: 17px;
            background: currentColor;
            margin-bottom: 5px;
        }
    }

    &__image {

        margin-bottom: 24px;

        img {
            width: 100%;
        }
    }
}