.table-1 {
    color: @color-sherpa-blue;
    thead {
        tr {
            th {
                color: @color-sunshade;
                font-size: 24px;
                img {
                    max-width: 110px !important;
                    height: auto;
                }
            }
        }
    }

    tbody {
        tr {
            th {
                text-align: right;
                border-right: 1px  solid @color-sunshade;

            }
            td {
                font-weight: 400;
            }
        }
    }
}