.b1 {
    
    background-color: #FFFFFF;
    padding: 70px 0 70px 0;
    position: relative;

    &-title {
        text-align: left;
        margin-bottom: 30px;
    }

    &-right {
        position: absolute;
        top: 70px;
        right: 0;
        width: 47%;
    }

    &-body {
        margin-top: 30px;
    }

    @media (max-width: 991px) {
        &-right {
            position: relative;
            right: auto;
            top: auto;
            width: 100%;
            margin-bottom: 30px;
        }
    }
    
}

