// User Dropdown box
.navbar-nav .open .dropdown-user {
    font-size: 14px;
    min-width: 300px;
    padding: 10px;
    right: -10px;
    left: auto;
    background-color: #fff;
    .img { margin-right: 10px; }
    .info {
        margin-left: 110px;
        overflow: hidden;
    }
    .btn-xs {
        font-size: 12px;
    }
}
.navbar {
    font-family: "Lucida Sans", "Lucida Grande", "Lucida Sans Unicode", "Luxi Sans", sans-serif;
    font-size: 14px;
}
.navbar-brand {
    font-size: 14px;
    line-height: 22px;
    padding: 15px 0;
    text-align: center;
    float: none;
    display: block;
    margin: 0 60px;
    @media (min-width: @screen-sm-min) {
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 0;
        margin-right: 0;
        font-size: 18px;
        line-height: 20px;
        text-align: left;
        float: left;
    }
}
.navbar-toggle {
    margin-right: 13px;
    padding: 6px 8px;
}
.navbar-toggle-left {
    float: left;
    margin-left: 13px;
    margin-right: 0;
}