.header {

    top: 0;
    width: 100%;
    background: @color-white;
    z-index: 1;

    position: fixed;

    height: 120px / @ds;

    .has-navbar & {
        top: 50px;
    }

    @media @tablet {
        position: fixed;
        height: 75px;
        z-index: 2;
    }

    @media @desktop {
        height: 120px;
        .easing-transition(all);

        .body--scrolled & {
            height: 75px;
        }
    }

    &__button {
        display: block;
        position: relative;
        height: 40px;
        width: 40px;
        background: transparent;
        border: 0;
        outline: 0;
        padding: 0;
        left: 20px;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);

        @media @tablet {
            display: none;
        }

        span {
            width: 60px / @ds;
            height: 6px / @ds;
            background: @color-sherpa-blue;
            display: block;
            position: absolute;

            top: 50%;
            transform: translateY(-50%);

            &::before, &::after {
                content: '';
                display: block;
                position: absolute;
                width: 60px  / @ds;
                height: 6px  / @ds;
                background: @color-sherpa-blue;
            }

            &::before {
                top: -6px;
            }

            &::after {
                top: 6px;
            }
        }

    }

    &__content {
        position: fixed;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        border-bottom: 0;
        transform: translateX(-100%);
        .transition-bezier();
        background: @color-white;
        top: 50px;

        .has-navbar & {
            top: 100px;
        }

        @media @tablet {
            max-width: 1180px;
            margin: 0 auto;
            position: relative;
            z-index: 2;
            height: 100%;
            top: 0;
            transform: none;

            .has-navbar & {
                top: 0;
            }
        }

        @media @desktop {
            text-align: center;
        }
    }

    &__logo-link {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    &__logo {
        margin-right: 20px;
        display: none;
        height: 100%;

        @media @desktop {
            display: inline-block;
        }

        @media @wide {
            margin-right: 116px;
        }
    }

    &__logo-scrolled {
        display: none;
    }

    &__logo-mobile {
        width: 150px / @ds;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);

        @media @tablet {
            left: 20px;
            z-index: 3;
            transform: translateY(-50%);
        }

        @media @desktop {
            display: none;
        }
    }

    .nav {
        font-size: 0;
        line-height: 0;
        height: 100%;

        padding-top: 30px / @ds;

        @media @tablet {
            vertical-align: top;
            display: inline-block;
            padding-top: 0;
            margin-left: 100px;

            &:before {
                height: 100%;
                content: '';
                vertical-align: middle;
                display: inline-block;
            }
        }

        @media @desktop {
            width: 720px;
            text-align: left;
            margin-left: 0;
        }

        @media @wide {
            width: 760px;
        }

        &__list {

            margin: 0;
            padding: 0;

            @media @tablet {
                vertical-align: middle;
                display: inline-block;

                &:before {
                    height: 100%;
                    content: '';
                    vertical-align: middle;
                    display: inline-block;
                }
            }
        }

        &__item {
            display: block;
            text-align: left;
            margin-bottom: 20 / @ds;

            @media @tablet {
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                margin: 0 20px 0 0;
                height: 60px;
                line-height: 60px;
            }

            @media @desktop {
                margin: 0 20px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            @media @wide {
                margin: 0 28px;
            }

            &--has-children {
                position: relative;
                margin-bottom: 5/ @ds;

                @media @tablet {
                    margin-bottom: 0;

                    &:hover {

                        .nav__dropdown {
                            pointer-events: initial;
                            opacity: 1;
                            .transition-bezier();
                            transform: translateX(-50%) translateY(0);
                        }
                    }
                }

                > .nav__link {

                    position: relative;

                    &:after {
                        content: '';
                        background: url('/images/arrow-green-down.png') center center no-repeat;
                        width: 20px / @ds;
                        height: 14px / @ds;
                        background-size: contain;
                        margin-left: 14px / @ds;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        margin-top: 1px;

                        @media @tablet {
                            display: none;
                        }
                    }
                }
            }
        }

        &__link {

            font-weight: 600;
            color: @color-sherpa-blue;
            text-transform: uppercase;
            line-height: normal;
            cursor: pointer;

            font-size: 36px / @ds;
            padding-left: 48px / @ds;

            @media @tablet {
                font-size: 14px;
                padding-left: 0;
                height: 60px;
                line-height: 60px;
            }

            @media @desktop {
                font-size: 16px;
                letter-spacing: 16px / 1000;
            }

            &:hover {
                text-decoration: none;
            }
        }

        &__dropdown {
            border-top: (4px / @ds) solid @color-sherpa-blue;
            margin: (9 / @ds) 0 0 0;
            padding: 0;

            @media @tablet {
                opacity: 0;
                pointer-events: none;
                position: absolute;
                top: 50px;
                background: @color-white;
                width: 164px;
                left: 50%;
                transform: translateX(-50%) translateY(-30%);
                border-top: 3px solid @color-sherpa-blue;
                margin: 0;
                padding: 0;
            }

            .nav__item {
                font-weight: 600;
                color: @color-sherpa-blue;
                text-transform: uppercase;
                border-bottom: 1px solid @color-white;
                display: block;
                cursor: pointer;

                background: @color-wild-sand;

                font-size: (26 / @ds);
                margin: 0;
                padding: 0;

                @media @tablet {
                    height: 40px;
                    line-height: 38px;
                    font-size: 14px;
                    text-align: center;
                    border-color: @color-black-haze;
                    padding-left: 0;
                    margin: 0;
                }

                &:hover {
                    text-decoration: none;
                }

                &:first-child {
                    border-top: none;
                }
            }

            .nav__link {
                display: block;

                height: 60 / @ds;
                line-height: 58 / @ds;
                font-size: 26 / @ds;

                @media @tablet {
                    height: 38px;
                    line-height: 38px;
                }
            }
        }
    }

    &__social {
        display: none;

        @media @tablet {
            display: inline-block;
            vertical-align: middle;
            height: 75px;
        }

        @media @desktop {
            margin-left: 20px;
        }

        @media @wide {
            margin-left: 20px;
        }

        .social {

            margin: 0;
            padding: 0;
            height: 100%;

            &:before {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                content: '';
            }

            &__item {
                display: inline-block;
                margin-right: 10px;
                vertical-align: middle;
                font-size: 22px;
                text-align: center;
                border-radius: 50%;
                .fa {
                    color: @color-sherpa-blue;
                }
                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    .fa {
                        color: lighten(@color-sherpa-blue, 10%);
                        
                    }
                }

                // &--facebook {
                //     width: 29px;
                //     height: 28px;
                //     border: 1px solid @color-sherpa-blue;
                //     border-radius: 50%;
                //     background: url('/images/icon-facebook.svg') center center no-repeat;
                //     background-size: 8px 16px;

                //     &:hover {
                //         background-color: @color-sherpa-blue;
                //         background-image: url('/images/icon-facebook-inverted.svg');
                //     }
                // }

                // &--youtube {
                //     width: 38px;
                //     height: 28px;
                //     background: url('/images/icon-youtube.png') center center no-repeat;
                //     background-size: 38px 28px;

                //     &:hover {
                //         background-image: url('/images/icon-youtube-inverted.png');
                //     }
                // }

            }

            &__link {
                width: 100%;
                height: 100%;
                display: block;
            }
        }
    }

}

.body--openedMenu {
    .header {
        &__content {
            transform: translateX(0);
        }
        &__button {
            span {
                background: rgba(0, 71, 80, 0);
                &::before, &::after {
                    top: 0;
                }
                &::before {
                    transform: rotate(45deg)
                }
                &::after {
                    transform: rotate(-45deg)
                }
            }
        }
    }
}

.body--openAnimation {
    .header {
        &__button {
            span {
                transition: background 0s .1s;
                &::before, &::after {
                    transition: top .1s, transform .1s .1s;
                }
            }
        }
    }
}

.body--closeAnimation {
    .header {
        &__button {
            span {
                transition: background 0s .1s;
                &::before, &::after {
                    transition: transform .1s, top .1s .1s;
                }
            }
        }
    }
}