.map {
    height: 565px;
}
.addresses {
    height: 470px;
    overflow: auto;
}
.addresses li {
    border-bottom: 1px solid #ccc;
    padding: 5px;
}
.addresses li .btns {
    float: left;
    margin-right: 8px;
}
.addresses li.active { 
	background-color: #EEE;
}
