.b4 {
    background-color: @color-sherpa-blue;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 30px 20px 20px 20px;
    color: #FFFFFF;
    a {
        color: #FFFFFF;
        font-size: 14px;
        opacity: 0.7;
        &:hover {
            text-decoration: none;
            opacity: 1;
        }
    }

    &-close {
        opacity: 0.7;
        cursor: pointer;
        width: 12px;
        height: auto;
        position: absolute;
        top: 7px;
        right: 7px;
        &:hover {
            opacity: 1;
        }
    }
}