.body-page-text_and_image, .body-page-our_technology {
    background: @color-white;
}

.ta {

    // text-align: center;

    &__white {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #FFFFFF;
    }

    &__heading {
        margin: (33 / @ds) 0 (42 / @ds) 0;

        @media @tablet {
            margin: 34px 0 21px 0;
        }

    }

    &__image {

        width: 100%;
        background: @color-black center center no-repeat;
        background-size: cover;
        border-bottom: 4px solid @color-sherpa-blue;
        margin-bottom: 33px;
        padding-bottom: 50%;

        @media @desktop {
            padding-bottom: 40%;
        }

    }

    &__text {
        // text-align: center;
        margin-bottom: 40px;
    }
}
