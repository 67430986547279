.b5 {
    &-title {
        color: @color-sherpa-blue;
        font-size: 12px;
        line-height: 1.3;
        display: block;
        &:hover {
            text-decoration: none;
        }
    }
}