.footer {
    min-height: 80px;
    border-top: 4px solid @color-sherpa-blue;
    font-size: 12px;
    color: @color-tundora;
    padding: 16px 0;
    background: @color-white;

    p {

        &:last-child {
            margin-bottom: 0;
        }
    }
}