@tablet: ~"only screen and (min-width: 768px)";
@desktop: ~"only screen and (min-width: 992px)";
@wide: ~"only screen and (min-width: 1200px)";

@color-white: #ffffff;
@color-wild-sand: #f4f4f4;
@color-silver: #bfbfbf;
@color-silver-chalice: #aaaaaa;
@color-sherpa-blue: #004750;
@color-black-haze: #eceded;
@color-tundora: #4a4a4a;
@color-black: #050505;
@color-persian-green: #00b2a9;
@color-alabaster: #fbfbfb;
@color-mercury: #e1e1e1;
@color-sunshade: #ffa02f;
@color-alabaster: #f9f9f9;
@color-nobel: #b6b5b5;
@color-dusty-grey: #9b9a9a;
@color-alto: #dddddd;

@ds: 2.4px; // mobile design scale

body {

    font-family: 'Open Sans', sans-serif;
    z-index: 1;
    background: @color-wild-sand;

    @media @tablet {
        z-index: 2;
    }

    @media @desktop {
        z-index: 3;
    }

    @media @wide {
        z-index: 4;
    }
}

.container {
    .container-fixed();

    @media (min-width: @screen-sm-min) {
        width: 100%;
    }
    @media (min-width: @screen-md-min) {
        width: 100%;
    }
    @media (min-width: @screen-lg-min) {
        width: 100%;
    }
}

.site-container {
    margin: 0;
    // padding: 50px 0 30px 0;
    padding: 50px 0 0 0;

    .body-page-timeline &, .body-page-grid & {
        padding-top: 80px;
    }

    .body-contacts & {
        padding-top: 70px;

    }

    .body-page-home & {
        padding-bottom: 0;
    }

    @media @tablet {
        // padding: 120px 0 30px 0;
        padding: 120px 0 0 0;

        .body-page-timeline &, .body-page-grid & {
            padding-top: 120px;
        }

        .body-contacts & {
            padding-top: 90px;
        }

        .body-page-home & {
            padding-top: 0;
        }
    }
}

@bezier-function: cubic-bezier(0.45, 0, 0.55, 1);

.transition-bezier() {
    transition: all .15s @bezier-function;
}

.heading {
    font-weight: 700;
    color: @color-sherpa-blue;

    font-size: 42px / @ds;
    text-align: center;
    margin: 0;

    &-left {
        text-align: left;
    }

    @media @tablet {
        font-size: 30px;
    }

    &--sub {
        font-size: 20px;

        @media @tablet {
            font-size: 24px;
        }
    }

    &--subpage {
        margin-bottom: 30px;

        @media @tablet {
            margin-bottom: 40px;
        }
    }
}

.button {
    border: 2px @color-sherpa-blue solid;
    color: @color-sherpa-blue;
    text-transform: uppercase;
    height: 60px;
    line-height: 56px;
    text-align: center;
    outline: 0;
    display: inline-block;
    padding: 0 30px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    background: transparent;
    .transition-bezier();

    @media @tablet {
        height: 46px;
        line-height: 42px;
        border-width: 2px;
        font-size: 18px;
        padding: 0 26px;
    }

    &--small {

        @media @tablet {
            height: 35px;
            line-height: 31px;
            border-width: 2px;
            padding: 0 16px;
            font-size: 14px;
        }
    }

    &:hover {
        background: @color-sherpa-blue;
        color: @color-white;
        text-decoration: none;
        outline: 0;
    }

    &:focus {
        outline: 0;
    }
}

.page-container {
    max-width: 1040px;
    margin: 0 auto;
    padding: 0 30px;

    &--wide {
        max-width: 1180px;
        padding: 0;
    }
}

.text {
    color: @color-tundora;
    font-size: 14px;
    line-height: 20px;

    @media @tablet {
        font-size: 14px;
        line-height: 24px;
    }
}

.section {
    padding: 0 0 8px 0;

    @media @tablet {
        padding: 0 0 0 0;
    }

    &--recenttests {
        padding-top: 28px;

        @media @tablet {
            padding-top: 40px;
        }
    }

    &--contacts {
        padding-top: 28px;

        @media @tablet {
            padding-top: 100px;
        }
    }

    &:last-child {
        padding-bottom: 0;
    }
}

@transition-bezier: cubic-bezier(0.45, 0, 0.55, 1);

.easing-transition( @property ) {
    transition: @property @transition-bezier .2s;
}

.container-image {
    padding-bottom: 59%;
    background: center center no-repeat;
    background-size: cover;

    @media @desktop {
        padding-bottom: 0;
        height: 70vh;
    }
}