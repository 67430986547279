.contact-form {

    &__content {
        background: @color-alabaster;
        border-top: 4px solid @color-sherpa-blue;
        padding: 45px 20px 33px 20px;
    }

    .control-label {
        color: @color-sherpa-blue;
        text-transform: uppercase;
        font-size: 14px;
    }

    .form-control {
        border-radius: 0;
    }

    &__fields {
        max-width: 974px;
        margin: 0 auto;

        @media @tablet {
            text-align: justify;
            font-size: 0;

            &:after {
                content: '';
                display: inline-block;
                width: 100%;
            }
        }
    }

    &__left, &__right {

        @media @tablet {
            display: inline-block;
            vertical-align: top;
        }

    }

    &__left {

        @media @tablet {
            width: 40%;
        }
    }

    &__right {

        @media @tablet {
            width: 55%;
        }

        .form-group {
            margin-bottom: 0;
        }
    }

    &__button-wrap {
        border: 1px solid @color-mercury;
        border-top: none;
        background: @color-white;
        padding: 11px 19px;
        text-align: right;
    }
}

.section--contacts {
    .alert {
        font-size: 12px;
    }
}