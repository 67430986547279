.grid {

    .page-container {

    }

    &__list {
        font-size: 0;
    }

    &__item {

        margin-bottom: 40px;
        text-align: center;

        @media @tablet {

            text-align: left;
            margin-right: 4%;
            margin-bottom: 30px;
            width: 22%;
            display: inline-block;
            vertical-align: top;

        }

        &:nth-child(4n) {
            margin-right: 0;
        }
    }

    &__image {

        width: 80%;
        max-width: 200px;
        margin: 0 auto 20px auto;

        img {
            width: 100%;
        }

        @media @tablet {
            width: 100%;
            max-width: 225px;
            margin: 0 0 20px;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 21px;
        color: @color-sherpa-blue;
        margin-bottom: 16px;

        &:after {
            content: '';
            width: 25px;
            height: 1px;
            background: @color-sherpa-blue;
            display: block;
            margin: 13px auto 0 auto;

            @media @tablet {
                margin: 13px 0 0;
            }
        }
    }

    &__job {
        font-weight: normal;
        font-size: 16px;
        color: @color-sherpa-blue;
        margin-bottom: 16px;
        min-height: 50px;
    }

    &__link {
        color: @color-persian-green;
        font-size: 16px;
        font-weight: 600;
    }

    &__link2 {
        color: @color-sherpa-blue;
        font-weight: bold;
        &:hover {
            text-decoration: none;
        }
    }

    &__phone {
        display: block;
        font-size: 16px;
        font-weight: 600;
        color: @color-sherpa-blue;
    }
}