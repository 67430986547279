.tech-benefits {

    background: @color-wild-sand;

    &__list {
        list-style-type: none;
        font-size: 14px;
        margin: 0;
        padding: 0;

        text-align: justify;
    }
}

.tech-benefit {

    border-top: 4px solid @color-sherpa-blue;
    background: @color-white;
    margin: 0 0 19px 0;
    display: block;
    padding: 20px 37px 29px 37px;

    @media @tablet {
        padding: 50px 59px;
        // display: inline-block;
        vertical-align: top;
    }

    @media @tablet {
        &--short {
            width: 32%;
        }

        &--medium {
            width: 66%;
            padding-left: 120px;
        }

        &--long {
            width: 100%;
            padding-left: 0;
        }
    }

    &__content {

        text-align: left;

        @media @tablet {
            .tech-benefit--long & {
                display: inline-block;
                width: calc(~"100% - 360px");
                vertical-align: top;
                padding-left: 20px;
            }
        }

    }

    &__title {
        color: @color-sherpa-blue;
        font-weight: 600;
        font-size: 20px;
        margin: 19px 0 26px 0;
        text-align: center;

        @media @tablet {
            margin: 0 0 19px 0;
            font-size: 21px;
            text-align: left;
        }
    }

    &__image {

        background: center center no-repeat;
        background-size: contain;
        width: 50px;
        height: 50px;
        margin: 0 auto;

        @media @tablet {
            margin-bottom: 25px;
            background: left center no-repeat;
            background-size: contain;
            height: 90px;
            width: 100%;

            .tech-benefit--long & {
                display: inline-block;
                width: 360px;
                background-position: center center;
                vertical-align: top;
                margin-top: 60px;
            }
        }
    }

    &__list {

        color: @color-tundora;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        li {

            font-weight: 600;
            color: @color-tundora;
            position: relative;
            font-size: 14px;
            margin-bottom: 20px;
            line-height: 20px;
            text-align: center;

            @media @tablet {
                line-height: 30px;
                font-size: 14px;
                text-align: left;
                margin-bottom: 0;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;

                border-radius: 50%;
                width: 3.3px;
                height: 3.3px;
                background: @color-sherpa-blue;

                left: 50%;
                transform: translateX(-50%);
                top: -12.5px;

                @media @tablet {
                    left: -20px;
                    top: 8px;
                    width: 4px;
                    height: 4px;
                }
            }
        }
    }
}