.body-page-news_list {
    background: @color-white;
}

.news {

    &__list {
        text-align: center;
    }
}

.news-item {

    text-align: left;
    border-bottom: 2px solid @color-wild-sand;
    padding-bottom: 34px;

    max-width: 1140px;
    margin: 0 auto;

    .clearfix();

    &__content {
        padding: 0 20px;
    }

    &__title {
        color: @color-sherpa-blue;
        font-size: 24px;
        font-weight: 600;
    }

    &__date {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 400;
        color: @color-dusty-grey;
        margin-bottom: 18px;

        &:before {
            content: '';
            display: block;
            height: 1px;
            width: 17px;
            background: currentColor;
            margin-bottom: 5px;
        }
    }

    &__container {

        @media @tablet {
            .clearfix();
        }
    }

    &__left {

        @media @tablet {
            width: 290px;;
            float: left;
            margin-right: 3%;
        }
    }

    &__right {

        @media @tablet {
            float: left;
            width: 100%;
            padding-right: 80px;

            &--half {
                width: calc(~"97% - 440px");
            }
        }
    }

    &__image {
        margin-bottom: 20px;
        width: 100%;

        img {
            width: 100%;
        }
    }

    &__summary {
        color: @color-tundora;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 23px;
    }
}

.archives {

    padding: 0 20px;

    @media @tablet {
        float: right;
        width: 150px;
    }

    &--mobile {
        @media @tablet {
            display: none;
        }
    }

    &--desktop {
        display: none;
        @media @tablet {
            display: block;
        }
    }

    &__title {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        color: @color-sherpa-blue;
        margin-bottom: 11px;

        &:after {
            margin-top: 12px;
            content: '';
            display: block;
            width: 25px;
            height: 1px;
            background: @color-tundora;
        }

    }

    &__link {
        font-size: 12px;
        color: @color-tundora;
        display: block;
        text-transform: uppercase;
        margin-bottom: 7px;
    }
}