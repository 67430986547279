.files-list {
    .list-unstyled();
    .row();
    &-item {
        .make-xs-column(4);
        .make-sm-column(3);
        .make-md-column(2);
        margin-bottom: @grid-gutter-width;
    }
    &-image-thumb {
        .img-responsive();
    }
    &-document {
        text-align: center;
        display: block;
        border: 1px solid @gray-lighter;
        background-color: @gray-lighter;
        margin-bottom: @grid-gutter-width;
        padding: 20px 3px;
        &:hover {
            text-decoration: none;
        }
        &-filename {
            display: block;
            line-height: 16px;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-filesize {
            font-size: 12px;
            color: @gray-light;
        }
    }
}
