.products {
    background: @color-white;
    text-align: center;

    margin-bottom: 60px;

    @media @tablet {
        margin-bottom: 100px;
    }

    &__content {
        max-width: 580px;
        margin: 0 auto;

        padding: 40px 20px;

        @media @desktop {
            padding: 60px 0 30px 0;
        }
    }

}

.list-products {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.product {

    color: @color-sherpa-blue;

    &__content {
        margin-bottom: 40px;
        @media @desktop {
            position: relative;

            text-align: left;

            .product:nth-child(even) & {
                text-align: right;
            }
        }
    }

    &__link {
        text-align: center;
        margin-top: 30px;
    }

    &__image {
        @media @desktop {
            position: absolute;
            top: 50%;

            .product:nth-child(odd) & {
                transform: translateY(-50%) translateX(-100%);
                left: -20px;
                margin-top: 30px;
            }

            .product:nth-child(even) & {
                transform: translateY(-50%) translateX(100%);
                right: -20px;
            }
        }
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 14px;

        @media @tablet {
            font-size: 24px;
        }
    }

    &__description {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 18px;

        @media @desktop {
            font-size: 18px;
        }
    }
}

