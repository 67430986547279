.feature-table {
    table {
        text-align: center;
        background: @color-white;

        border-top: 4px solid @color-sherpa-blue;
    }

    th {
        text-align: center;
        height: 100px;
        vertical-align: middle;
        font-weight: 700;
        color: @color-nobel;
        font-size: 18px;
        border-bottom-width: 1px;
        border-left: 1px solid @color-alto;
        border-right: 1px solid @color-alto;

        &:nth-child(2) {
            font-size: 24px;
            color: @color-sherpa-blue;

        }
    }

    tr {
        &:nth-child(odd) {
            background: @color-alabaster;
        }
    }

    thead {
        tr {
            &:nth-child(odd) {
                background: @color-white;
            }
        }
    }

    td {
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        height: 50px;
        vertical-align: middle;
        border-left: 1px solid @color-alto;
        border-right: 1px solid @color-alto;

        &:first-child {
            color: @color-tundora;
            font-size: 14px;
        }
    }

    &__yes {
        color: @color-persian-green;
    }

    &__no {
        color: @color-sunshade;
    }
}