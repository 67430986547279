@import 'bootstrap';
@import 'flexboxgrid.min.css';
@import '../../../../node_modules/font-awesome/less/font-awesome';
@import 'variables';
// @import 'errors';
@import 'header';
@import 'footer';
@import '../../../../node_modules/swiper/src/less/swiper';
@import 'swiper';
@import 'offcanvas';
@import 'gmaps';
@import '../navbar';
@import 'navbar-hide-left-button';
@import 'fancybox';
@import 'gallery';
@import '_b1';
@import '_b2';
@import '_b3';
@import '_b4';
@import '_b5';

.has-navbar {
    padding-top: 50px;
}

.skip-to-content {
    .sr-only();
    .sr-only-focusable();
    &:active,
    &:focus {
        position: absolute;
    }
}

table {
    .table();
}

.site-container {
    .container();
}

@import 'custom/main';

.main-container {
    max-width: 1210px;
    margin: 0 auto;
    padding: 0 30px;
    @media (max-width: 768px) {
        padding: 0 15px;
    }
}

.footer {
    &-logo {
        &-link {
            margin: 0 15px;
            vertical-align: middle;
            img {
                display: inline-block;
                vertical-align: middle;
            }
            .l1 {
                
                height: 70px;
            }
            .l2 {
                height: 110px;
            }
        }
    }

    &-text {
        padding-top: 30px;
        text-align: center;
    }

    @media (max-width: 768px) {
        text-align: center;
    }
}

.title-1 {
    font-size: 21px;
}


.pt-10 {
    padding-top: 10px;
}

.pt-60 {
    padding-top: 60px;
}


.pt-80 {
    padding-top: 80px;
}

.pb-80 {
    padding-bottom: 80px;
}

.mb-30  {
    margin-bottom: 30px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-80 {
    margin-bottom: 80px;
}

.fs-14 {
    font-size: 14px;
}

.bold {
    font-weight: bold;
}

.container-narrow {
    max-width: 500px;
    margin: 0 auto;
}

.row-30 {
    margin-left: -30px;
    margin-right: -30px;
}

.col-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.row-40 {
    margin-left: -40px;
    margin-right: -40px;
}

.col-40 {
    padding-left: 40px;
    padding-right: 40px;
}