.template-home {
    margin: 0;

    @media @tablet {
    }
}

.top {

    position: relative;

    &__carousel {
    }

    &__down {
        position: absolute;
        background: url('/images/arrow-down.png') center center no-repeat;
        background-size: contain;
        width: 45px / @ds;
        height: 28px / @ds;
        bottom: 50px / @ds;
        left: 50%;
        transform: translateX(-50%);

        @media @tablet {
            width: 34px;
            height: 21px;
            bottom: 23px;
        }
    }
}

.carousel {

    list-style-type: none;
    padding: 0;
    margin-bottom: 0;

    &__item {
        position: relative;
    }

    &__image {
        height: calc(~"100vh - 60px");
        max-height: 500px;
        background-size: cover;
        background-position: center center;



        @media @tablet {
            max-height: 920px;
        }
    }

    &__title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        color: @color-white;
        text-align: center;
        font-size: 48px / @ds;
        font-weight: bold;

        @media @tablet {
            font-size: 40px;
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 116px / @ds;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;

        @media @tablet {
            bottom: 66px;
        }

        li {
            display: inline-block;
            border-radius: 50%;
            background: @color-silver-chalice;
            cursor: pointer;

            width: 14px / @ds;
            height: 14px / @ds;
            margin: 0 (8px / @ds);

            @media @tablet {
                width: 11px;
                height: 11px;
                margin: 0 10px;
            }

            .transition-bezier();

            &.slick-active {
                background: @color-white;
            }
        }
    }
}

.benefits {

    background: @color-white;
    font-size: 0;

    @media @tablet {
        padding-top: 40px;
        margin-bottom: 60px;
    }

    &__content {
        max-width: 1210px;
        margin: 0 auto;

        @media @tablet {
            padding: 0 20px;
        }
    }

    &__heading {
        text-align: center;
        background: @color-wild-sand;
        padding: (60px / @ds) 20px;
        margin: 0;
        border-bottom: (6px / @ds) @color-sherpa-blue solid;

        @media @tablet {
            background: @color-white;
            padding: 0;
            margin-bottom: 40px;
            border-bottom: none;
        }

    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin-bottom: 0;

        @media @tablet {
            text-align: justify;

            &:after {
                content: '';
                display: inline-block;
                width: 100%;
            }
        }

    }

    &__item {

        @media @tablet {
            width: 40%;
            vertical-align: top;
            text-align: left;

        }
    }

    .benefit {

        border-bottom: (6px / @ds) @color-sherpa-blue solid;
        padding: 20px 37px 29px 37px;

        text-align: center;

        @media @tablet {
            text-align: left;
        }

        @media @tablet {
            border-bottom: none;
            display: inline-table;
            width: 46%;
            padding: 0;
            margin-bottom: 25px;
        }

        &__image {
            background: center center no-repeat;
            background-size: contain;
            width: 120px / @ds;
            height: 120px / @ds;
            margin: 0 auto;

            @media @tablet {
                width: 115px;
                height: 100%;
                display: table-cell;
                vertical-align: middle;
                margin: 0;
            }
        }

        &__content {

            @media @tablet {
                display: table-cell;
                padding-left: 44px;
                vertical-align: middle;
                text-align: left;
            }
        }

        &__list {

            ul {
                list-style-type: none;
                padding: 0;
            }

            li {
                // font-weight: 600;
                color: @color-tundora;
                position: relative;
                font-size: 30px / @ds;
                margin-bottom: 48px / @ds;
                line-height: 20px;

                @media @tablet {
                    margin-bottom: 12px;
                    font-size: 14px;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;

                    border-radius: 50%;
                    width: 8px / @ds;
                    height: 8px / @ds;
                    background: @color-sherpa-blue;

                    left: 50%;
                    transform: translateX(-50%);
                    top: -30px / @ds;

                    @media @tablet {
                        left: -20px;
                        top: 8px;
                        width: 4px;
                        height: 4px;

                    }
                }
            }
        }

        &__heading {
            font-size: 36px / @ds;

            margin: (46px / @ds) 0 (63px / @ds) 0;
            color: @color-sherpa-blue;

            @media @tablet {
                font-size: 21px;
                text-align: left;
            }
        }
    }
}

.applications {
    background: @color-wild-sand;
    text-align: center;
    overflow: hidden;

    margin-bottom: 60px;

    @media @tablet {
        margin-bottom: 100px;
    }

    &__heading {

        display: none;

        @media @tablet {
            font-size: 21px;
            padding: 0 20px;
            background: @color-wild-sand;
            height: 60px;
            line-height: 60px;
            margin: 0;
            display: block;
        }

        &:first-of-type {
            font-size: 42px / @ds;
            margin: 0;
            line-height: 60px / @ds;
            padding-top: 63px / @ds;
            padding-bottom: 51px / @ds;
            display: block;

            @media @tablet {
                display: none;
            }
        }
    }

    .application {

        position: relative;
        text-align: center;
        background: @color-white;

        @media @tablet {
            text-align: left;
        }

        &__content {
            max-width: 1210px;
            margin: 0 auto;

            .clearfix();
        }

        &__details {

            padding-bottom: 36px / @ds;

            @media @tablet {

                width: 440px;
                float: right;
                position: relative;
                padding: 30px 0 30px 30px;

                &:before {
                    display: none;
                    content: '';
                    position: absolute;
                    transform: skewX(-25deg);
                    top: 0;
                    height: 100%;
                    right: 0;
                    width: 100%;
                    background: @color-white;
                    transform-origin: right top;
                }
            }

        }

        &__background {
            background-size: cover;
            width: 100%;
            padding-bottom: 50%;
            background: url('/images/temp/application.jpg') center center no-repeat;

            @media @tablet {
                background-position: right center;
                transform: translateX(170px);
                right: 50%;
                position: absolute;
                height: 100%;
                padding: 0;
            }
        }

        &:nth-of-type(odd) {

            @media @tablet {
                .application__background {
                    background-position: left center;
                    left: 50%;
                    transform: translateX(-170px);
                }

                .application__details {
                    float: left;

                    &:before {
                        transform: skewX(25deg);
                        transform-origin: left top;
                    }
                }
            }
        }

        &__heading {
            position: relative;
            font-size: 30px / @ds;
            text-transform: uppercase;
            margin-top: 41px /@ds;

            @media @tablet {
                font-size: 21px;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }

        &__list {
            position: relative;

            ul {
                list-style: none;
                padding: 0;

                li {
                    // font-weight: 600;
                    color: @color-tundora;
                    position: relative;
                    margin-bottom: 22px / @ds;

                    font-size: 28px / @ds;

                    @media @tablet {
                        font-size: 16px;
                        margin-bottom: 16px;
                    }

                    &:before {
                        width: 6px / @ds;
                        height: 6px / @ds;
                        background: @color-sherpa-blue;
                        content: '';
                        border-radius: 50%;
                        display: inline-block;
                        margin-right: 4px / @ds;
                        vertical-align: middle;

                        @media @tablet {
                            display: block;
                            position: absolute;
                            top: 8px;
                            left: -25px;
                            width: 6px;
                            height: 6px;
                            margin-right: 0;
                        }
                    }

                }

            }
        }
    }

    &__button {

        margin: (30px / @ds) 0;

        @media @tablet {
            margin: 30px 0;

        }
    }
}